export default function cleanKenyaPhoneNumber(number) {
  // Remove any spaces or non-numeric characters
  number = number.replace(/\D/g, "");
  // console.log(number);
  // Check if the number starts with '0' and add '254', removing the '0'
  if (number.startsWith("0")) {
    number = "254" + number.substring(1); // Remove '0' and prepend '254'
  }

  // If the number has 9 digits (e.g., 712345678), prepend '254'
  if (number.length === 9) {
    number = "254" + number;
  }

  // Validate the format after ensuring it starts with '254'
  if (!number.startsWith("254")) {
    return { error: "Invalid number format. Must start with 254 or 0." };
  }

  // Check if the number has too many digits after starting with '254'
  if (number.length > 12 && number.startsWith("254")) {
    return {
      error: "Invalid number length for Kenya. Length must be 12 digits.",
    };
  }

  const isZeroAfter254 = number[3] === "0";
  if (isZeroAfter254 && number[4] !== "1" && number[4] !== "7") {
    return {
      error:
        "Invalid number format. The digit after 254 must be either 1 or 7.",
    };
  }

  let cleanedNumber;
  if (isZeroAfter254) {
    cleanedNumber = "254" + number.substring(4); // Remove the '0' after '254'
  } else {
    cleanedNumber = number;
  }

  // Check final length to ensure it's exactly 12 digits
  if (cleanedNumber.length !== 12) {
    return {
      error: "Invalid number length for Kenya. Length must be 12 digits.",
    };
  }
  // console.log(cleanedNumber);
  return { cleanedNumber };
}

// export default function cleanKenyaPhoneNumber(number) {
//     if (!number.startsWith('254')) {
//         return { error: 'Invalid number format. Must start with 254.' };
//     }
//
//     const isZeroAfter254 = number[3] === '0';
//     if (isZeroAfter254 && number[4] !== '1' && number[4] !== '7') {
//         return { error: 'Invalid number format. The digit after 2540 must be either 1 or 7.' };
//     }
//
//     let cleanedNumber;
//     if (isZeroAfter254) {
//         cleanedNumber = '254' + number.substring(4);
//     } else {
//         cleanedNumber = number;
//     }
//
//     if (cleanedNumber.length !== 12) {
//         return { error: 'Invalid number length for Kenya. Length must be 12 digits.' };
//     }
//
//     return { cleanedNumber };
// }
